import { ITINERARYDETAILS } from "src/common/constants";
export const ITINERARYDETAILS_FETCH_DATA = `${ITINERARYDETAILS}_GET_DATA`;
export const ITINERARYDETAILS_FETCH_DATA_CUSTOM_DATES = `${ITINERARYDETAILS}_GET_DATA_CUSTOM_DATES`;
export const ITINERARYDETAILS_FETCH_INVOKED = `${ITINERARYDETAILS}_GET_INVOKED`;
export const ITINERARYDETAILS_FETCH_SUCCESS = `${ITINERARYDETAILS}_GET_SUCCESS`;
export const ITINERARYDETAILS_FETCH_FAILED = `${ITINERARYDETAILS}_GET_FAILED`;

export const fetchItineraryDetails = (id, timeFrames) => ({
  type: ITINERARYDETAILS_FETCH_DATA,
  timeFrames,
  id,
});
export const fetchItineraryDetailsCustomDates = ({
  startDate,
  endDate,
  id,
}) => ({
  type: ITINERARYDETAILS_FETCH_DATA_CUSTOM_DATES,
  startDate,
  endDate,
  id,
});
export const fetchItineraryDetailsInvoked = (id) => ({
  type: ITINERARYDETAILS_FETCH_INVOKED,
  id,
});
export const fetchItineraryDetailsSuccess = (payload, id) => ({
  type: ITINERARYDETAILS_FETCH_SUCCESS,
  payload,
  id,
});
export const fetchItineraryDetailsFailed = (error, id) => ({
  type: ITINERARYDETAILS_FETCH_FAILED,
  error,
  id,
});
