import SVGIcon from "src/components/SVGIcon";

const CalendarIcon = ({ title = "Calendar", ...props }) => {
  return (
    <SVGIcon viewBox="0 0 26 29" title={title} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-862.000000, -4042.000000)">
          <g transform="translate(858.977097, 4040.500000)">
            <rect
              transform="translate(16.000000, 16.000000) rotate(-270.000000) translate(-16.000000, -16.000000) "
              x="0"
              y="0"
              width="32"
              height="32"
            ></rect>
            <path
              d="M9,2 C8.2272,2 7.6,2.6272 7.6,3.4 L7.6,4.8 L6.2,4.8 C4.66,4.8 3.4,6.06 3.4,7.6 L3.4,27.2 C3.4,28.74 4.66,30 6.2,30 L25.8,30 C27.34,30 28.6,28.74 28.6,27.2 L28.6,7.6 C28.6,6.06 27.34,4.8 25.8,4.8 L24.4,4.8 L24.4,3.4 C24.4,2.6272 23.7728,2 23,2 C22.2272,2 21.6,2.6272 21.6,3.4 L21.6,4.8 L10.4,4.8 L10.4,3.4 C10.4,2.6272 9.7728,2 9,2 Z M6.2,11.8 L25.8,11.8 L25.8,25.8 C25.8,26.5728 25.1728,27.2 24.4,27.2 L7.6,27.2 C6.8272,27.2 6.2,26.5728 6.2,25.8 L6.2,11.8 Z"
              id="Shape"
              fill="currentColor"
            ></path>
          </g>
        </g>
      </g>
    </SVGIcon>
  );
};

export default CalendarIcon;
