import React, { Fragment } from "react";
import PaperAirplane from "src/icons/PaperAirplane";
import NoData from "src/icons/NoData";
import Planet from "src/icons/Planet";
import SadCloud from "src/icons/SadCloud";
import Satellite from "src/icons/Satellite";

/**
 * Loader used on components doing async requests. It can show a animated loading icon, dim the background
 * and switch over to an error state if the component fails to load its resource.
 */

interface LoadingI {
  active?: boolean;
  addContainer?: boolean;
  background?: boolean;
  dimmer?: boolean;
  error?: any;
  lightMode?: boolean;
  loadingText?: string;
  paddingX?: number;
  paddingY?: number;
}

const Loading = ({
  active,
  addContainer,
  background,
  dimmer,
  error,
  lightMode,
  loadingText,
  paddingX,
  paddingY,
}: LoadingI) => {
  // If the loader is not actively loading or in an error state, it should not render anything
  if (!active && !error) return null;
  const renderContent = () => {
    if (!error) {
      // Default loader
      return (
        <Fragment>
          <img src="/images/ctm-logo-landscape.png" alt="CTM Logo" />
          {loadingText && <p>{loadingText}</p>}
        </Fragment>
      );
    }
    // If an error has a code, let's use that code for our checking
    if (error.code) error = error.code;
    switch (error) {
      case 403:
        return (
          <span className="loading-error-text">
            You do not have the permission required to use this tool. Please
            contact{" "}
            <a href="mailto:na.online@travelctm.com">na.online@travelctm.com</a>{" "}
            for more information.
          </span>
        );
      case 404:
      case 204:
      case "noContent":
        return (
          <Fragment>
            <NoData style={{ marginBottom: "20px", maxHeight: "200px" }} />
            <h4 className="loading-error-header">NO DATA TO SHOW</h4>
            <span className="loading-error-text">
              There is no data available right now.
            </span>
          </Fragment>
        );
      case "pdfError":
        return (
          <span>
            This invoice is currently unavailable. Please reach out to{" "}
            <a href="mailto:na.online@travelctm.com">na.online@travelctm.com</a>
            .
          </span>
        );
      case "noCityPairs":
        return (
          <Fragment>
            <PaperAirplane
              title="No results."
              style={{ marginBottom: "20px", maxHeight: "200px" }}
            />
            <span className="loading-error-text">
              No search results were returned for these city pairs.
            </span>
          </Fragment>
        );
      case "noFlights":
        return (
          <Fragment>
            <PaperAirplane
              title="No flights booked."
              style={{ marginBottom: "20px", maxHeight: "200px" }}
            />
            <span className="loading-error-text">
              It looks like there are no active{" "}
            </span>
            <span className="loading-error-text">
              travelers for the next week.
            </span>
          </Fragment>
        );
      case "noTravellers":
        return (
          <Fragment>
            <Planet
              title="No active travellers."
              style={{ maxHeight: "150px" }}
            />
            <span className="loading-error-text">
              No active travelers within the selected dates.
            </span>
          </Fragment>
        );
      case 500:
      case 503:
      case 504:
      case "serverError":
        return (
          <Fragment>
            <SadCloud title="Oh Snap!" />
            <h4 className="loading-error-header">
              OH SNAP, SOMETHING WENT WRONG
            </h4>
            <span className="loading-error-text">
              Looks like we were not able to connect to the server.
            </span>
            <span className="loading-error-text">
              Please try again in a few minutes.
            </span>
          </Fragment>
        );
      default:
        return (
          <Fragment>
            <Satellite title="Whoops!" />
            <h4 className="loading-error-header">WHOOPS</h4>
            <span className="loading-error-text">
              We can't load anything right now.
            </span>
            <span className="loading-error-text">
              Please try again in a few minutes.
            </span>
          </Fragment>
        );
    }
  };

  // Set dynamic/prop based styles if we are using the dimmer
  const style: any = {};
  if (dimmer) {
    style.backgroundColor = lightMode
      ? "rgba(255,255,255, 0.6)"
      : "rgba(0,0,0,0.6)";
    if (paddingX) {
      style.left = `${paddingX}px`;
      style.right = `${paddingX}px`;
    }
    if (paddingY) {
      style.top = `${paddingY}px`;
      style.bottom = `${paddingY}px`;
    }
    if (!error && active) style.zIndex = "999999";
  }
  if (background) style.zIndex = "0";

  return (
    <div
      style={
        addContainer
          ? {
              width: "100%",
              height: "100%",
              minHeight: "500px",
              position: "relative",
            }
          : {}
      }
    >
      <div className="loading-container" style={style}>
        <div className="loading-centered-div">{renderContent()}</div>
      </div>
    </div>
  );
};

export default Loading;
