import { Image, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import Profile from "./Profile";
import NotificationIcon from "src/icons/NotificationIcon";
import ChatMessageIcon from "src/icons/ChatMessageIcon";
import { setGoogleAnalyticsEvent } from "src/common";
import { SSOLinkI } from "src/types/SSOLink";

/**
 * Renders company specific logo, global search and current user profile options
 */

interface HeaderI {
  agentChatLink?: SSOLinkI;
  ctmLogo: string;
  handleChatOpen: () => void;
  headerState: {
    isCondensed: boolean;
    isSticky: boolean;
  };
  isImpersonating: boolean;
  initializeImpersonation: (params: {
    email: string;
    id: string;
    token: string;
  }) => void;
  clearImpersonation: () => void;
}

const Header = ({
  agentChatLink,
  ctmLogo,
  handleChatOpen,
  headerState,
  isImpersonating,
  initializeImpersonation,
  clearImpersonation,
}: HeaderI) => {
  const { isCondensed, isSticky } = headerState;

  return (
    <header
      className={`header ${isCondensed ? " condensed" : ""}${
        isSticky ? " sticky" : ""
      }${isImpersonating ? " impersonation" : ""}`}
    >
      <div className="toggle-logo">
        <label htmlFor="toggle-aside" aria-hidden="true">
          <Icon className="hamburger" name="bars" />
          <span className="visually-hidden">Toggle Side Menu</span>
        </label>
      </div>
      <div className="ctm-logo" role="heading" aria-level={1}>
        <Image src={ctmLogo} alt="CTM Logo" aria-label="Smart Portal" />
      </div>
      <div />
      <div className="right-side-header">
        {agentChatLink && (
          <button
            className="unstyled-button"
            aria-label="Agent Chat Toggle"
            onClick={handleChatOpen}
          >
            <ChatMessageIcon />
          </button>
        )}
        <button
          className="unstyled-button beamerTrigger"
          aria-label="Beamer Toggle"
          onClick={() => {
            setGoogleAnalyticsEvent({
              action: "portal_dashboard_beamer_click",
            });
          }}
        >
          <NotificationIcon />
        </button>
        <div className="profile">
          <Profile
            initializeImpersonation={initializeImpersonation}
            clearImpersonation={clearImpersonation}
          />
        </div>
      </div>
    </header>
  );
};

export default connect((state: any) => ({
  headerState: state.application.header,
}))(Header);
