import { DATE_FORMAT, TIME_FORMAT } from "src/common";
import {
  STATE_FETCH_SUCCESS,
  STATE_FETCH_INVOKED,
  STATE_FETCH_FAILED,
} from "src/redux/actions/user";
import { UserI } from "src/types/User";

export const initialState = {
  data: {} as UserI,
  loading: true,
  error: null as Error,
};

export type UserState = typeof initialState;

const user = (state: UserState = initialState, action): UserState => {
  switch (action.type) {
    case STATE_FETCH_INVOKED:
      return {
        ...state,
        loading: true,
      };
    case STATE_FETCH_SUCCESS:
      // // First we must make sure that any missing widgets have defaults applied to them
      // action.payload.widgets = setWidgetDefaults(action.payload.widgets);
      return {
        ...state,
        loading: false,
        data: {
          ...action.payload,
          dateFormat:
            typeof action.payload.dateTimeFormat === "string"
              ? action.payload.dateTimeFormat.toUpperCase().split(" ")[0]
              : DATE_FORMAT,
          dateTimeFormat:
            typeof action.payload.dateTimeFormat === "string"
              ? `${
                  action.payload.dateTimeFormat.toUpperCase().split(" ")[0]
                } ${TIME_FORMAT}`
              : `${DATE_FORMAT} ${TIME_FORMAT}`,
        },
      };
    case STATE_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default user;
